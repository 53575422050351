<template>
  <div class="form-btn">
    <div class="biao-ti">
      原手机号无法接收短信
    </div>
    <div class="nei-rong">
      1、请点击下放反馈给我们,即可申请更改绑定手机。<br/>
      2、拨打官方客服电话{{mobile}}
    </div>
  </div>
</template>
<script>

import http from "../../../api/http";

export default {
  name: 'noMobileTip',
  mixins: [],
  data(){
    return {
      mobile:''
    }
  },
  mounted() {
    http.get('/portal/index/get_ke_hu_tel').then(da => {
      if (da.code === 1) this.mobile = da.data.content
    })
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.biao-ti {
  color: @pei-se;

}
.nei-rong{
  margin-top: 9px;
  color: @gray-7;
}
</style>
